import React from 'react';
import { Dropdown, Option, SelectionEvents, OptionOnSelectData } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';

const LanguageSelect: React.FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const supportedLanguages = (i18n.options.supportedLngs || []).filter(lng => lng !== 'cimode');
  const [currentLanguage, setCurrentLanguage] = React.useState<string>(i18n.language);

  const handleLanguageChange = React.useCallback((_event: SelectionEvents, data: OptionOnSelectData) => {
    const selectedLanguage = data.optionValue as string;
    i18n.changeLanguage(selectedLanguage);
  }, [i18n]);

  React.useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setCurrentLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return (
    <Dropdown id="language-select" value={currentLanguage} onOptionSelect={handleLanguageChange} className={classes.container}>
      {supportedLanguages.map((language) => (
        <Option key={language} value={language}>
          {language}
        </Option>
      ))}
    </Dropdown>
  );
};

export default LanguageSelect;