import { createSlice } from '@reduxjs/toolkit';

interface IUserInfo {
    userId: string;
    userName: string;
    userEmail: string;
    companyName: string;
}

const initialState: IUserInfo = {
    userId: '',
    userName: '',
    userEmail: '',
    companyName: '',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserInfo: () => initialState,
        setUserInfo: (state: IUserInfo, action: { payload: IUserInfo }) => {
            state.userId = action.payload.userId;
            state.userName = action.payload.userName;
            state.userEmail = action.payload.userEmail;
            state.companyName = action.payload.companyName;
        },
    },
});

export const { setUserInfo, resetUserInfo } = userSlice.actions;

export default userSlice.reducer;
