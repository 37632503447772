import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import { msalConfig } from './authConfig'
import { Provider } from 'react-redux'
import { store } from '@store/store'
import App from './app.tsx'
import './index.css'
import './i18n.ts'

// Use Fluent Provider as the root component
const fluentProviderStyle: React.CSSProperties = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column'
}

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <FluentProvider theme={webLightTheme} style={fluentProviderStyle}>
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </Provider>
      </FluentProvider>
    </StrictMode>,
  )
});