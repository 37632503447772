import { makeStyles } from "@fluentui/react-components";

export const useAvatarStyles = makeStyles({
  avatarContainer: {
    padding: '6px',
    cursor: 'pointer',
  }
});

export const useContentStyles = makeStyles({
  userContent: {
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: '250px',
    height: '1px',
    margin: '16px 0',
  },
});