import React from 'react';
import Router from './router';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { setUserInfo } from '@store/userSlice';
import { fetchUserInfo } from '@services/user';
import { Spinner } from '@fluentui/react-components';
import { InteractionStatus } from '@azure/msal-browser';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { instance, inProgress } = useMsal();
  const [loading, setLoading] = React.useState(true);

  // Fetch user info
  const getUserInfo = React.useCallback(async () => {
    // 1. Check if user is authenticated from AAD
    const accounts = instance.getAllAccounts();
    if (!accounts.length) {
      return;
    }
    console.log(accounts);
    const { homeAccountId, name = '', username, idToken = '' } = accounts[0];
    // 2. Exchange token from Xandr side
    const res = await fetchUserInfo({ AADToken: idToken, UserName: name, Email: username });
    console.log(res);
    // TODO: yren - Get user info from response
    const userInfo = {
      userId: homeAccountId,
      userName: name,
      userEmail: username,
      companyName: 'Microsoft'
    };
    // 3. Set user info to redux store
    dispatch(setUserInfo(userInfo));
  }, [dispatch, instance]);

  const init = React.useCallback(async () => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }
    try {
      setLoading(true);
      await getUserInfo();
    } finally {
      setLoading(false);
    }
  }, [getUserInfo, inProgress]);

  React.useEffect(() => {
    init();
  }, [init]);

  return loading ? <Spinner size='extra-small' style={{ height: '100%' }} /> : <Router />;
};

export default App;