import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from '@constants/env';

const apiClient = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
  timeout: 10000,
});

const handleError = (error: AxiosError) => {
  console.error('request error:', error);
  return Promise.reject(error);
};

const retryRequest = async (config: AxiosRequestConfig, retries: number = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await apiClient(config);
    } catch (error) {
      if (i === retries - 1) {
        throw error;
      }
    }
  }
};

apiClient.interceptors.request.use(
  (config) => config,
  handleError
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    await handleError(error);
    if (error.config) {
      return retryRequest(error.config);
    }
    return Promise.reject(error);
  }
);

const fetchData = async (url: string, config: AxiosRequestConfig = {}) => {
  return await apiClient.get(url, config);
};

const postData = async (url: string, data: unknown, config: AxiosRequestConfig = {}) => {
  return await apiClient.post(url, data, config);
};

export { fetchData, postData };
