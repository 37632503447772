import {
  bundleIcon,
  FluentIcon,
  HomeFilled,
  HomeRegular,
} from '@fluentui/react-icons';
import { ERoutes } from '@constants/routes';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IPathItem {
  path: string;
  name?: string;
  icon?: FluentIcon;
}

interface IPathMapping {
  [key: string]: IPathItem;
}

const HomeIcon = bundleIcon(HomeFilled, HomeRegular);

export const useBreadcrumbItems = (): IPathItem[] => {
  const { pathname } = useLocation();
  const { t } = useTranslation('common');

  // Define the path mapping, supportting localization
  const PATH_MAPPING: IPathMapping = {
    [ERoutes.Home]: {
      path: ERoutes.Home,
      icon: HomeIcon,
    },
    [ERoutes.CaseList]: {
      path: ERoutes.CaseList,
      name: t('MyCases'),
    },
    [ERoutes.ContactSupport]: {
      path: ERoutes.ContactSupport,
      name: t('ContactSupport'),
    },
    [ERoutes.CaseDetail]: {
      path: ERoutes.CaseList,
      name: t('MyCases'),
    },
  };

  return pathname.split('/').map((item) =>
    PATH_MAPPING[`/${item}`] ?? { path: '', name: item }
  );
};