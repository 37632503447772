import { OptionProps } from "@fluentui/react-components";

export enum EStep {
  TopAndCat,
  CaseDetail,
  ContactDetail,
}

export enum ECateogry {
  // ProductSupport
  Invest = 'Invest',
  Curate = 'Curate',
  Monetize = 'Monetize',
  YieldAnalytics = 'YieldAnalytics',

  // Audit
  BrandInquiries = 'BrandInquiries',
  CreativeAudit = 'CreativeAudit',
  DomainAudit = 'DomainAudit',
  AntiMalvertising = 'AntiMalvertising',

  // BidderSSP&DataIntegrationsSupport
  BidderSupportBidderAPI = 'BidderSupportBidderAPI',
  DataManagementTaxonomyUpdates = 'DataManagementTaxonomyUpdates',
  ExternalSupply = 'ExternalSupply',

  // ClientFinance
  GeneralBilling = 'GeneralBilling',
  FraudClaim = 'FraudClaim',
  ResoldRevenueSellerPayment = 'ResoldRevenueSellerPayment',
  UpdateOfCustomerInformation = 'UpdateOfCustomerInformation',
  CreditSetup = 'CreditSetup',
  Collections = 'Collections',
  FinanceAccessControlRequest = 'FinanceAccessControlRequest',
  CreditCardPayment = 'CreditCardPayment',
  NeedPrepaymentInvoice = 'NeedPrepaymentInvoice',
  YieldAnalyticsRelatedFinanceRequest = 'YieldAnalyticsRelatedFinanceRequest',

  // AccountSupport
  ContractInquiry = 'ContractInquiry',
  GoogleAdManagerSeatRequest = 'GoogleAdManagerSeatRequest',

  // ProgrammaticDeals
  NewProgrammaticGuaranteedDeal = 'NewProgrammaticGuaranteedDeal',
  EditExistingProgrammaticGuaranteedDeal = 'EditExistingProgrammaticGuaranteedDeal',
}

export enum ContactFormKey {
  subject = 'subject',
  description = 'description',
  priority = 'priority',
  ccEmails = 'ccEmails',
  relatedMembers = 'relatedMembers',
  attachments = 'attachments',
}

export enum EFieldType {
  text = 'text',
  file = 'file',
  select = 'select',
  multiText = 'multiText',
  prioritySelect = 'prioritySelect',
}

export enum EPriority {
  level0 = '0',
  level1 = '1',
  level2 = '2',
  level3 = '3',
}

export interface IFormConfig {
  title: string;
  required: boolean;
  type: EFieldType;
  options?: OptionProps[];
  showCategory?: ECateogry[];
}

// 'title' and 'text' in the options are mapped to the translation keys
export const FORM_CONFIG: Record<ContactFormKey, IFormConfig> = {
  [ContactFormKey.subject]: {
    type: EFieldType.text,
    title: 'Subject',
    required: true,
  },
  [ContactFormKey.description]: {
    type: EFieldType.multiText,
    title: 'Description',
    required: true,
  },
  [ContactFormKey.priority]: {
    type: EFieldType.select,
    title: 'Priority',
    required: true,
    options: [
      {
        value: EPriority.level3,
        text: 'PriorityLevel3',
      },
      {
        value: EPriority.level2,
        text: 'PriorityLevel2',
      },
      {
        value: EPriority.level1,
        text: 'PriorityLevel1',
      },
      {
        value: EPriority.level0,
        text: 'PriorityLevel0',
      },
    ],
  },
  [ContactFormKey.ccEmails]: {
    type: EFieldType.text,
    title: 'CCEmails',
    required: false,
  },
  [ContactFormKey.relatedMembers]: {
    type: EFieldType.select,
    title: 'AddRelatedMembers',
    required: false,
  },
  [ContactFormKey.attachments]: {
    type: EFieldType.file,
    title: 'Attachments',
    required: false,
    showCategory: [ECateogry.NewProgrammaticGuaranteedDeal, ECateogry.EditExistingProgrammaticGuaranteedDeal],
  },
};

export interface IContactInfo {
  step: EStep;
  subject: string;
  ccEmails: string;
  category: ECateogry;
  priority: EPriority;
  attachments: string;
  description: string;
  relatedMembers: string;
  submissionCountry: string;
  checkOutageCriteria: boolean;
}