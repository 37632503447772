import React from "react";
import { NavLink } from 'react-router-dom';
import { useBreadcrumbItems } from "./hook";
import {
  Breadcrumb,
  mergeClasses,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
} from "@fluentui/react-components";
import { useStyles } from './style';

interface IProps {
  className?: string | undefined;
}

const BreadcrumbComponent: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const breadcrumbItems = useBreadcrumbItems();

  return (
    <Breadcrumb size="large" className={mergeClasses(classes.container, props.className)}>
      {breadcrumbItems.map((item, index) => (
        <React.Fragment key={`breadcrumb-${index}`}>
          {!!index && <BreadcrumbDivider />}
          <BreadcrumbItem>
            <NavLink to={item.path}>
              <BreadcrumbButton current={index === breadcrumbItems.length - 1}>
                {item.icon && <item.icon fontSize={24} />}{item.name}
              </BreadcrumbButton>
            </NavLink>
          </BreadcrumbItem>
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;