import { createSlice } from '@reduxjs/toolkit';
import { ECateogry, EPriority, EStep, IContactInfo } from '@constants/contact';

const initialState: IContactInfo = {
  step: EStep.TopAndCat,
  category: ECateogry.Invest,
  subject: '',
  ccEmails: '',
  priority: EPriority.level3,
  description: '',
  attachments: '',
  relatedMembers: '',
  submissionCountry: '',
  checkOutageCriteria: false,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetContactInfo: () => initialState,
    setStep: (state: IContactInfo, action: { payload: EStep }) => {
      state.step = action.payload;
    },
    setContactField: (state: IContactInfo, action: { payload: Partial<IContactInfo> }) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setStep, setContactField, resetContactInfo } = contactSlice.actions;

export default contactSlice.reducer;
